/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "flatpickr/dist/flatpickr.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
    --ion-font-family: "Lato", sans-serif;
}

/*.flatpickr-mobile{
    display: none;
}*/

.display-flex {
    display: flex;
    align-items: center;
}

ion-label {
    text-align: left !important;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}

.tlist {
    font-size: 0.8em;
    width: 100%;
    td,
    th {
        padding: 1em;
        text-align: left;
        line-height: 1.5em;

        ul {
            padding-left: 1em;
        }
    }

    thead {
        th:first-child {
            border-left: none;
        }
        th:last-child {
            border-right: none;
        }

        th {
            padding: 0.5em;
            color: var(--ion-color-red);
            border: 1px solid var(--ion-color-red);
        }
    }

    tbody {
        td {
            border-bottom: 1px solid var(--ion-color-medium);
        }
    }

    .img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        background: url() center center no-repeat;
        background-size: cover;
    }
}

ion-button {
    --border-radius: 0.35em;
}

.h1-title {
    font-size: 48px;
    font-weight: bold;
    font-family: 'Montserrat';
}

.m-font {
    font-family: 'Montserrat';
}

